import { Address, PartnerAddress } from 'pleinchamp-api-client';

export function fromCamelToKebab(input: string) {
  if (!input) {
    return '';
  }
  return input.replace(/([A-Z])/g, '-$1').toLowerCase();
}

export function stripTags(input: string) {
  return input.replace(/<[^>]*>?/gm, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeJsonParse(input: any) {
  if (typeof input !== 'object') {
    return JSON.parse(input);
  }
  return input;
}

export function formatSize(bytes: number, decimals = 0) {
  if (bytes === 0) {
    return '0o';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
}

export const urlRegex = /^\/[a-z0-9.\-_/?&='%]*$/i;

export type AddressInfos = { link: string; address: string };
export function getAddressInfos(address: Address | PartnerAddress): AddressInfos | undefined {
  const { line1, line2, line3, zipPostcode, city, countryCode } = address;
  const addressParts = [
    line1,
    line2,
    line3,
    zipPostcode?.length || city?.length ? `${address.zipPostcode} ${address.city}` : undefined,
    countryCode,
  ].filter(part => part && part.length > 0);

  if (addressParts.length === 0) {
    return undefined;
  }

  const finalAddress = addressParts.filter(part => !!part).join(', ');
  const link = `https://maps.google.com/?q=${finalAddress}`;
  return { address: finalAddress, link };
}

export const fullNamespaces = [
  'actu',
  'auth',
  'common',
  'community',
  'contact',
  'events',
  'guides',
  'journal',
  'landing',
  'meteo',
  'notifications',
  'partners',
  'preferences',
  'profile',
  'search',
  'services',
  'stocks',
  'treatment-schedule',
  'competition',
  'innovations',
];
// TODO: remove this hack to be able to effectively manage wordings
export const defaultNamespaces = [...fullNamespaces, 'common', 'auth', 'search', 'actu'];

const accentsMap: { [key: string]: string } = {
  a: 'á|à|ã|â|À|Á|Ã|Â',
  c: 'ç|Ç',
  e: 'é|è|ê|É|È|Ê',
  i: 'í|ì|î|Í|Ì|Î',
  n: 'ñ|Ñ',
  o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
  u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
};

export const slugify = (text: string) =>
  Object.keys(accentsMap).reduce((acc, cur) => acc.replace(new RegExp(accentsMap[cur], 'g'), cur), text);
